import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/global/SEO'
import { Section } from '../components/global/Section'
import { H2 } from '../components/global/Headings'
import RichText from '../components/global/RichText'

const query = graphql`
  query PrivacyPolicyQuery	{
    contentfulPage(slug: {
      eq: "privacy-policy"
    }) {
      title
      slug
      content {
        json
      }
      seoTitle
      seoDescription
      seoSocialMediaImage {
        resize(width: 1200, height: 630) {
          src
        }
      }
    }
  }
`

const PrivacyPolicy = () => (
  <StaticQuery
    query={query}
    render={({ contentfulPage }) => 
      <Layout>
        <SEO title={contentfulPage.seoTitle} description={contentfulPage.seoDescription} image={contentfulPage.seoSocialMediaImage && contentfulPage.seoSocialMediaImage.resize.src} />
        <Section>
          <H2 icon>{contentfulPage.title}</H2>
          <div className="mx-auto lg:w-3/5 mb-16">
            <RichText json={contentfulPage.content.json} />
          </div>
        </Section>
      </Layout>
    }
  />
)

export default PrivacyPolicy